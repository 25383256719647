import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { useGetAccountWithEmailMutation } from "../../app/api/public/account";
import { HttpStatus } from "../../app/constants/HttpStatus";
import { AccountStatus } from "../../app/models/Account";
import { Button } from "../shared/Button";
import { FieldController } from "../shared/FieldController";
import { Form } from "../shared/Form";
import { TextField } from "../shared/TextField";
import { SignUpMarkdown } from "./SignUpMarkdown";
import { SignUpMessage } from "./SignUpMessage";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpGetAccountWithEmailFormProps = {
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpGetAccountWithEmailForm = (
  props: SignUpGetAccountWithEmailFormProps,
) => {
  const { onChange } = props;

  const [getAccount] = useGetAccountWithEmailMutation();

  const { t } = useTranslation();
  const { errorMessage, fields, legend, submit } = t(
    "features.account.SignUpGetAccountWithEmailForm",
  ) as unknown as {
    errorMessage: Record<AccountStatus | HttpStatus, string>;
    fields: {
      email: { label: string; messages: { invalid: string; required: string } };
    };
    legend: string;
    submit: string;
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .required(fields.email.messages.required)
          .email(fields.email.messages.invalid),
      }),
    ),
  });

  return (
    <>
      <Form
        actions={
          <Button
            dataLayer={{
              eventAction: "clic",
              eventCategory: "pre-home",
              eventLabel: "mail",
            }}
            fluid
            isSubmitting={isSubmitting}
            type="submit"
            variant="action"
          >
            {submit}
          </Button>
        }
        errorMessage={
          errors.root?.server.message && (
            <SignUpMarkdown onChange={onChange}>
              {errors.root.server.message}
            </SignUpMarkdown>
          )
        }
        name="SignUpGetAccountWithEmailForm"
        onSubmit={handleSubmit(async (values) => {
          try {
            const account = await getAccount(values).unwrap();
            if (account.status === AccountStatus.INACTIVE) {
              onChange({ step: SignUpStateType.CONFIRM_EMAIL, account });
            } else {
              onChange({
                step: SignUpStateType.RESULT,
                message: errorMessage[account.status],
                session: { email: account.email },
              });
            }
          } catch (_error) {
            const error = _error as { status: keyof typeof errorMessage };
            setError("root.server", {
              message:
                errorMessage[error.status] ??
                errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
            });
          }
        })}
      >
        <Form.Fieldset>
          <Form.Legend>{legend}</Form.Legend>
          <FieldController
            control={control}
            name="email"
            render={(controllerProps) => (
              <TextField
                {...controllerProps.field}
                autoComplete="username"
                errorMessage={
                  controllerProps.field.errorMessage && (
                    <SignUpMarkdown onChange={onChange}>
                      {controllerProps.field.errorMessage}
                    </SignUpMarkdown>
                  )
                }
                isRequired
                label={fields.email.label}
                type="email"
              />
            )}
          />
        </Form.Fieldset>
      </Form>
      <SignUpMessage onChange={onChange} />
    </>
  );
};
