import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  useConfirmEmailMutation,
  useSendConfirmEmailMutation,
} from "../../app/api/public/account";
import { HttpStatus } from "../../app/constants/HttpStatus";
import { Account } from "../../app/models/Account";
import { Button } from "../shared/Button";
import { FieldController } from "../shared/FieldController";
import { Form } from "../shared/Form";
import { Markdown } from "../shared/Markdown";
import { TextField } from "../shared/TextField";
import { SignUpMarkdown } from "./SignUpMarkdown";
import { SignUpMessage } from "./SignUpMessage";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpConfirmEmailFormProps = {
  account: Account;
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpConfirmEmailForm = (props: SignUpConfirmEmailFormProps) => {
  const { account, onChange } = props;

  const [confirmEmail] = useConfirmEmailMutation();
  const [sendConfirmEmail] = useSendConfirmEmailMutation();
  React.useEffect(() => {
    sendConfirmEmail({
      email: account.email,
      referenceActivation: account.referenceActivation,
    });
  }, [account.email, account.referenceActivation, sendConfirmEmail]);

  const { t } = useTranslation();
  const { errorMessage, fields, legend, submit } = t(
    "features.account.SignUpConfirmEmailForm",
  ) as unknown as {
    errorMessage: Record<HttpStatus, string>;
    fields: {
      code: {
        description: string;
        label: string;
        messages: { invalid: string; required: string };
      };
    };
    legend: string;
    submit: string;
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: { code: "" },
    resolver: yupResolver(
      yup.object().shape({
        code: yup
          .string()
          .required(fields.code.messages.required)
          .length(6, fields.code.messages.invalid),
      }),
    ),
  });

  return (
    <>
      <Form
        actions={
          <Button
            dataLayer={{
              eventAction: "clic",
              eventCategory: "pre-home",
              eventLabel: "confirmation_mail",
            }}
            fluid
            isSubmitting={isSubmitting}
            type="submit"
            variant="action"
          >
            {submit}
          </Button>
        }
        errorMessage={
          errors.root?.server.message && (
            <SignUpMarkdown onChange={onChange}>
              {errors.root.server.message}
            </SignUpMarkdown>
          )
        }
        name="SignUpConfirmEmailForm"
        onSubmit={handleSubmit(async (values) => {
          try {
            await confirmEmail({
              ...values,
              referenceActivation: account.referenceActivation,
            }).unwrap();
            onChange({ step: SignUpStateType.CONFIRM_ACCOUNT, account });
          } catch (_error) {
            const error = _error as { status: keyof typeof errorMessage };
            if (
              error.status === HttpStatus.BAD_REQUEST ||
              error.status === HttpStatus.MISDIRECTED_REQUEST ||
              error.status === HttpStatus.NOT_FOUND
            ) {
              setError("code", { message: errorMessage[error.status] });
            } else {
              setError("root.server", {
                message: errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
              });
            }
          }
        })}
      >
        <Form.Fieldset>
          <Form.Legend>{legend}</Form.Legend>
          <FieldController
            control={control}
            name="code"
            render={(controllerProps) => (
              <TextField
                {...controllerProps.field}
                description={
                  <Markdown
                    options={{
                      overrides: {
                        SendConfirmEmailButton: {
                          component: "button",
                          props: {
                            type: "button",
                            onClick: () =>
                              sendConfirmEmail({
                                email: account.email,
                                referenceActivation:
                                  account.referenceActivation,
                              }),
                          },
                        },
                      },
                    }}
                  >
                    {fields.code.description}
                  </Markdown>
                }
                errorMessage={
                  controllerProps.field.errorMessage && (
                    <SignUpMarkdown onChange={onChange}>
                      {controllerProps.field.errorMessage}
                    </SignUpMarkdown>
                  )
                }
                isRequired
                label={fields.code.label}
                type="text"
              />
            )}
          />
        </Form.Fieldset>
      </Form>
      <SignUpMessage onChange={onChange} />
    </>
  );
};
