import { useTranslation } from "react-i18next";

import { Button } from "../shared/Button";
import { Separator } from "../shared/Separator";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpMessageProps = {
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpMessage = (props: SignUpMessageProps) => {
  const { onChange } = props;

  const { t } = useTranslation();
  const { action, text } = t("features.account.SignUpMessage") as unknown as {
    action: { text: string };
    text: string;
  };

  return (
    <>
      <Separator variant="or" />
      <Button
        dataLayer={{
          eventAction: "clic",
          eventCategory: "pre-home",
          eventLabel: "parcours_alternatif",
        }}
        fluid
        type="button"
        variant="action"
        onClick={() =>
          onChange({
            step: SignUpStateType.GET_ACCOUNT_WITH_CHARACTER_CODE,
          })
        }
      >
        {action.text}
      </Button>
      <p>{text}</p>
    </>
  );
};
