import { publicApi } from ".";
import { Account } from "../../models/Account";
import { User } from "../../models/User";
import { reCaptcha } from "../../services/reCaptcha";

const baseUrl = "/accounts";

export const accountApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    confirmAccount: builder.mutation<void, Account>({
      query: (arg) => ({ url: baseUrl, method: "POST", data: arg }),
    }),
    confirmEmail: builder.mutation<
      void,
      Pick<Account, "referenceActivation"> & { code: string }
    >({
      query: (arg) => ({
        url: `${baseUrl}/email-codes/${arg.code}/validate`,
        method: "POST",
        data: arg,
      }),
    }),
    confirmSignUp: builder.query<void, { code: string; username: string }>({
      query: (arg) => ({
        url: `${baseUrl}/confirm`,
        method: "POST",
        data: arg,
      }),
    }),
    forgotPasswordSendEmail: builder.mutation<void, Pick<User, "email">>({
      query: (arg) => ({
        url: `${baseUrl}/forgot-password`,
        method: "POST",
        data: arg,
      }),
    }),
    forgotPasswordSubmit: builder.mutation<
      void,
      { code: string; password: string; username: string }
    >({
      query: (arg) => ({
        url: `${baseUrl}/forgot-password/${arg.code}/confirm`,
        method: "POST",
        data: arg,
      }),
    }),
    getAccountWith6CharacterCode: builder.mutation<Account, { code: string }>({
      queryFn: async (arg, _queryLifecycleApi, _extraOptions, fetch) => {
        const reCaptchaToken = await reCaptcha.execute(
          "getAccountWith6characterCode",
        );
        const response = await fetch({
          url: `${baseUrl}/code-6`,
          method: "POST",
          data: { ...arg, reCaptchaToken },
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: response.data as Account };
      },
    }),
    getAccountWith14CharacterCode: builder.mutation<Account, { code: string }>({
      queryFn: async (arg, _queryLifecycleApi, _extraOptions, fetch) => {
        const reCaptchaToken = await reCaptcha.execute(
          "getAccountWith14characterCode",
        );
        const response = await fetch({
          url: `${baseUrl}/code-14`,
          method: "POST",
          data: {
            ...arg,
            code: arg.code.replace(/\s+/g, "").replace(/[oO]/g, "0"),
            reCaptchaToken,
          },
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: response.data as Account };
      },
    }),
    getAccountWithEmail: builder.mutation<Account, Pick<Account, "email">>({
      queryFn: async (arg, _queryLifecycleApi, _extraOptions, fetch) => {
        const reCaptchaToken = await reCaptcha.execute("getAccountWithEmail");
        const response = await fetch({
          url: `${baseUrl}/email`,
          method: "POST",
          data: { ...arg, reCaptchaToken },
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: response.data as Account };
      },
    }),
    sendActivationEmail: builder.mutation<
      void,
      { email?: string; username?: string }
    >({
      query: (arg) => ({
        url: `${baseUrl}/activation-email`,
        method: "POST",
        data: arg,
      }),
    }),
    sendConfirmEmail: builder.mutation<
      void,
      Pick<Account, "email" | "referenceActivation">
    >({
      query: (arg) => ({
        url: `${baseUrl}/email-codes`,
        method: "POST",
        data: arg,
      }),
    }),
  }),
});

export const {
  useConfirmAccountMutation,
  useConfirmEmailMutation,
  useConfirmSignUpQuery,
  useForgotPasswordSendEmailMutation,
  useForgotPasswordSubmitMutation,
  useGetAccountWith6CharacterCodeMutation,
  useGetAccountWith14CharacterCodeMutation,
  useGetAccountWithEmailMutation,
  useSendActivationEmailMutation,
  useSendConfirmEmailMutation,
} = accountApi;
