import { Auth } from "@aws-amplify/auth";

import { publicApi } from ".";
import { currentUserApi } from "../private/currentUser";
import { appApi } from "./app";

export const authApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    autoSignIn: builder.query<null, void>({
      queryFn: async (_arg, queryLifecycleApi) => {
        const getAppConfig = await queryLifecycleApi.dispatch(
          appApi.endpoints.getAppConfig.initiate(),
        );
        if (getAppConfig.error) {
          return { error: getAppConfig.error };
        }

        try {
          await Auth.currentAuthenticatedUser();
          await queryLifecycleApi.dispatch(
            currentUserApi.endpoints.getCurrentUser.initiate(),
          );
        } catch {}

        return { data: null };
      },
    }),
    manualSignIn: builder.mutation<null, { email: string; password: string }>({
      queryFn: async (arg, queryLifecycleApi) => {
        try {
          await Auth.signIn(arg.email.toLowerCase(), arg.password);

          const getCurrentUser = await queryLifecycleApi.dispatch(
            currentUserApi.endpoints.getCurrentUser.initiate(),
          );
          if (getCurrentUser.error) {
            return { error: getCurrentUser.error };
          }

          queryLifecycleApi.dispatch(
            currentUserApi.endpoints.signInHistory.initiate(),
          );

          return { data: null };
        } catch (_error) {
          const error = _error as { code: string };
          return {
            error: {
              status:
                String(error) ===
                "NotAuthorizedException: Password attempts exceeded"
                  ? "LimitExceededException"
                  : error.code,
            },
          };
        }
      },
    }),
  }),
});

export const { useAutoSignInQuery, useManualSignInMutation } = authApi;
